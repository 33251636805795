.patient-close {
    position: absolute;
    right: 10%;
}

.patient-container{
    position: relative;
    max-width: 800px;
    margin: 0 auto;
}

.patient-form {
    padding-top: 37px;
    width: 100%;

    .wrap-residences {
        &.disabled {
            background-color: lightgrey;
            border-radius: 10px;
            padding-bottom: 15px;

            .subtitle {
                color: #B0B0B0;
            }
            .form-group {
                label {
                    color: #B0B0B0;
                }
                input {
                    background-color: #B0B0B0;
                }
            }
        }
    }

    .subtitle {
        display: block;
        width: 100%;
        height: 27px;
        margin: 31px 38px 18px 1px;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 6.4px;
        text-align: center;
        text-transform: uppercase;
        color: $brand-primary;
    }

    .form-group {
        color: red;
        position: relative;
        margin-bottom: 10px;
        width: 100%;

        &.text-center {
            text-align: center;
        }
        &.text-right {
            text-align: right;
        }

        label {
            color: $brand-secondary;
            font-size: 16px;
            line-height: 1.83;
            letter-spacing: 0.24px;
            display: block;

            &.wrap-error {
                color: red;
            }

            &.no-hosting  {
                font-size: 19px;

                label {
                    font-size: 19px;
                }
            }
        }
        input {
            color: $brand-primary;
            height: 56px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding: 15px 20px;
            border-radius: 4px;
            border: solid 1px #d9dce2;
            background-color: #fff;
            text-transform: capitalize;
            width: 100%;

            &[type=email],&[type=password],&[type=date] {
                text-transform: none;
            }
            &[type=radio] {
                margin-top: 15px;
                max-width: 30px;
                max-height: 30px;
            }

            &.error {
                border: 1px solid red;
            }

            &.hidden {
                position: absolute;
                bottom: 0;
                left: 0;
                opacity: 0;
                width: 1px;
                height: 1px
            }
        }

        input[type=checkbox] {
            &.self-center {
                margin: 0 !important;
            }
            color: $brand-primary;
            height: 25px;
            border-radius: 4px;
            border: solid 1px #d9dce2;
            background-color: #fff;
            margin-left: 10px;
            width: 25px;
        }

        textarea {
            width: 100%;
            height: 123px;
            padding: 17.5px 20px;
            border-radius: 4px;
            border: 1px solid #d9dce2;

            &.error {
                border: 1px solid red;
            }
        }

        .wrap-img {
            position: relative;
            width: 100%;
            height: 156px;
            margin-top: 26px;
            border-radius: 4px;
            border: solid 1px #d9dce2;
            background-color: #f9fafd;

            &.no-back {
                background-color: transparent;
            }

            &.client {
                height: 240px;

                a {
                    margin: 0 auto;
                    width: 200px;
                }

                img {
                    max-width: 160px;
                    margin: 0 auto 15px;
                }
            }

            img {
                position: relative;
                max-width: 98px;
                margin: 0 auto;
                display: block;
                margin-top: 20px;
                border-radius: 50%;
            }

            a {
                margin: 8px 27px 0;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.83;
                letter-spacing: 0.24px;
                text-align: center;
                color: $brand-primary;
                display:block;
                position: relative;

                &.dropdown-item {
                    margin: 0 auto 10px;
                    border-bottom: 1px solid $brand-primary;
                }
            }
        }
    }

    .wrap-select {
        position: relative;
        margin-bottom: 30px;

        &.no-margin {
            margin-bottom: 0;
        }

        select {
            background: url("/img/arrow-down.svg") transparent no-repeat 95% center;
            height: 56px;
            border-radius: 0;
            border-bottom: solid 1px $brand-primary;
            border-left: 0 solid;
            border-right: 0 solid;
            border-top: 0 solid;
            color: $brand-primary;
            -moz-appearance: none;
            -ms-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            font-size: 16px;
            padding-left: 20px;
            text-transform: uppercase;
            letter-spacing: 6.4px;
            text-align: left;
            width: 295px;

            &.error {
                color: red;
                border-bottom: solid 1px red;
            }
        }

        &.type2 {
            margin-bottom: 0;

            select {
                background: url("/img/arrow-down.svg") transparent no-repeat 95% center;
                height: 56px;
                border: solid 1px #d9dce2;
                border-radius: 4px;
                color: $brand-primary;
                -moz-appearance: none;
                -ms-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                padding: 15px 20px;
                line-height: 1.63;
                letter-spacing: 0.32px;
                text-align: left;
                text-transform: capitalize;
                width: 100%;
            }
        }

    }

    .save-patient, .btnSave {
        width: 251px;
        height: 57px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 50px auto 137px auto;
        padding: 16px 20px;
        border-radius: 4px;
        letter-spacing: 5.7px;
        text-transform: uppercase;
        background-color: $brand-primary;
        border: 0;
        color: white;
    }

    .cancel {
        width: 251px;
        height: 57px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 50px auto 137px auto;
        letter-spacing: 5.7px;
        text-transform: uppercase;
        color: $brand-primary;
    }

}

.search-patient {
    background-image: url("/img/search-icon.svg");
    background-size: 27px auto;
    background-position: center center;
    background-repeat: no-repeat;
    width: 47px;
    height: 47px;
    display: block;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-radius: 4px;
    border: solid 1px #eff1f4;
    background-color: #fff;
    position: absolute;
    left: -55px;
    top: 113px;

    &.open {
        background-image: url("/img/close.svg");
    }

    &.swal {
        left: auto;
        right: 0;
        top: 10px;
    }
}

.close-search-patient {

    background-size: 27px auto;
    background-position: center center;
    background-repeat: no-repeat;
    width: 47px;
    height: 47px;
    display: block;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-radius: 4px;
    border: solid 1px #eff1f4;
    background-color: #fff;
    position: absolute;
    right: 30px;
    top: 10px;
}

.wrap-search-patient {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 300px;
    max-height: 600px;
    overflow-y: scroll;
    padding: 33px 0 57px;
    border-radius: 4px;
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.4);
    background-color: #fff;
    top: 105px;
    left: 0;

    .wrap-input {
        background-image: url('/img/search-icon.svg');
        background-position: 95% center;
        background-repeat: no-repeat;
        background-size: 24px auto;
        width: 499px;
        height: 49px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        border-radius: 4px;
        border: solid 1px #eff1f4;
        background-color: #fff;

        input {
            border: 0;
            height: 47px;
            padding-left: 15px;
            width: 85%;
        }
    }

    .patient-list {
        margin-top: 22px;
        width: 100%;

        .item {
            border-top: 1px solid #eff1f4;
            width: 100%;
            height: 50px;
            display: flex;
            align-content: center;
            justify-content: space-between;
            padding-top: 15px;
            cursor: pointer;

            label {
                display: block;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                letter-spacing: 0.32px;
                height: 100%;
                width: 100%;
                margin: 0;
                cursor: pointer;

                &.name {
                    color: #3b3d41;
                    padding-left: 60px;
                }
                &.visits {
                    color: #b0b3b7;
                    padding-left: 240px;
                }
            }
        }
    }
}

.wrap-search-client {
    margin-bottom: 50px;

    .wrap-input {
        background-image: url('/img/search-icon.svg');
        background-position: 95% center;
        background-repeat: no-repeat;
        background-size: 24px auto;
        width: 499px;
        height: 49px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        border-radius: 4px;
        border: solid 1px #eff1f4;
        background-color: #fff;
        display: flex;

        input {
            border: 0;
            height: 47px;
            padding-left: 15px;
            width: 300px;
        }

        .btnStrd {
            margin: 0;
        }
    }

}


.medical-assessment, .medical-report {

    .patient-form {

        .patient-container {
            max-width: 90%;

            @include media-breakpoint-up(xl) {
                max-width: 1100px;
            }

            .form-group {

                .wrap-items {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: start;
                    margin-bottom: 30px;
                    height: 550px;

                    &.therapies {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        justify-content: start;
                        margin-bottom: 30px;
                        //height: 270px;

                        .col-item {
                            flex-direction: row;
                        }
                    }

                    &.therapies-extra {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        justify-content: start;
                        margin-bottom: 30px;
                        height: 700px;

                        .col-item {
                            flex-direction: row;
                            justify-content: space-between;

                            input {

                            }
                            select {

                            }
                            label {
                                width: 205px;
                            }
                        }
                    }

                    .col-item {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        margin-left: 15px;
                        margin-right: 15px;
                        margin-bottom: 15px;
                        min-height: 55px;
                        width: auto;

                        input {
                            &[type=radio] {
                                margin-top: 15px;
                                margin-right: 15px;
                                max-width: 30px;
                                max-height: 30px;
                                height: 30px;
                                width: 30px;
                            }
                        }

                        select {
                            margin-left: 13px;
                            max-width: 160px;
                            min-width: 160px;
                            height: 100%;
                        }

                        .wrap-input {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }
                }

                &.patient-info {

                    .wrap-img {
                        background-color: transparent;
                        border: 0;
                        height: auto;
                        margin-top: 0;
                        width: auto;

                        img {
                            margin: 0;
                            object-fit: cover;
                            height: 85px;
                            width: 85px;

                            @include media-breakpoint-up(xl) {
                                height: 185px;
                                width: 185px;
                                max-width: 185px;
                            }
                        }
                    }

                    .wrap-text {
                        margin-top: 10px;
                        margin-left: 15px;

                        label {
                            font-size: 23px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.21;
                            letter-spacing: normal;
                            text-align: left;
                            margin: 0;
                            color: $brand-primary;
                        }

                        span {
                            opacity: 0.5;
                            font-family: Mukta;
                            font-size: 13px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.38;
                            letter-spacing: 0.32px;
                            text-align: left;
                            color: #000;
                        }
                    }

                }

                label {
                    margin-bottom: 3px;
                    min-width: 60px;

                    &.subtitle {
                        background-color: $brand-primary;
                        color: white;
                        font-weight: 700;
                        margin-bottom: 15px;
                        margin-top: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                textarea {
                    height: 200px;
                }

                select {
                    background: url("/img/arrow-down.svg") transparent no-repeat 95% center;
                    height: 56px;
                    border-radius: 4px;
                    border: solid 1px #d9dce2;
                    color: $brand-primary;
                    -moz-appearance: none;
                    -ms-appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
                    font-size: 16px;
                    padding-left: 20px;
                    text-align: left;
                    width: 100%;

                    &.error {
                        color: red;
                        border-bottom: solid 1px red;
                    }

                    &.therapies {
                        margin-bottom: 5px !important;
                    }
                }

                input[type=checkbox]{
                    &.self-center {
                        margin: 0 !important;
                    }
                    margin: 0 15px 0 0 !important;
                    gap: 0;
                    padding: 0;
                    min-width: 25px;
                }

                &.small-input {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;

                    .wrap {
                        display: flex;
                        justify-content: center;
                        align-items: end;

                        input {
                            width: 100%;
                        }

                        span {
                            color: $brand-primary;
                            display: block;
                            height: 100%;
                            margin-bottom: 10px;
                            margin-left: 8px;
                        }
                    }

                }
            }

            .history {
                display: block;
                text-align: right;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                letter-spacing: 0.32px;
                text-align: right;
                color: #1f3666;
            }

            .wrap-residences-days {
                border: solid 1px #eff1f4;
                background-color: #fff;
                margin-top: 30px;
                width: 100%;
                padding: 19px 0 25px;

                .wrap-days {
                    height: 40px;
                    border-bottom: solid 1px #eff1f4;

                    a {
                        line-height: 1.63;
                        letter-spacing: 0.32px;
                        color: #b0b3b7;
                    }
                }
            }

            .btn {
                background-color: $brand-primary;
                border: 0;
                color: white;
                display: block;
                height: 40px;
                font-size: 12px;
                text-transform: uppercase;
                padding-left: 10px;
                padding-right: 10px;
                letter-spacing: 5.7px;
                width: auto;
                margin: 0 auto;

                &.add-therapy, &.add-images, &.add-product {
                    height: 57px;
                    width: 100%;
                }
            }

            .delete-therapy, .delete-product {
                background-color: transparent;
                background-image: url("/img/close.svg");
                background-repeat: no-repeat;
                background-size: 100% auto;
                border: 0;
                margin: 0;
                display: inline-block;
                height: 12px;
                width: 12px;
                position: absolute;
                right: 20px;
                top: 20px;

                img {
                    width: 100%;
                }
            }

        }//.patient-container
    }//.patient-form
}

.slick-slider {

    .slick-arrow {
        color: $brand-primary;

        &.slick-prev {
            left: 8px;
            z-index: 2;

            &:before {
                color: $brand-primary;
            }
        }
        &.slick-next {
            right: 8px;
            z-index: 2;

            &:before {
                color: $brand-primary;
            }
        }
    }
}

.btnStrd {
    width: 251px;
    height: 57px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 50px auto 137px auto;
    padding: 16px 20px;
    border-radius: 4px;
    letter-spacing: 5.7px;
    text-transform: uppercase;
    background-color: $brand-primary;
    border: 0;
    color: white;

    &:hover {
        background-color: lightgray;
        color: $brand-primary;
    }

    &.small {
        color: white;
        font-size: 16px;
        width: 270px;
        height: 40px;
        letter-spacing: 4.2px;
    }
}

.head-w-m {

    .wrap-right {
        .room-number {
            margin-left: 0;
            text-align: right;
        }
        .residence-date {
            text-align: right;
            margin-top: 6px;
        }
    }

    .room-number {
        display: block;
        font-size: 18px;
        font-weight: normal;
        line-height: 1.63;
        letter-spacing: 0.32px;
        text-align: left;
        color: #b0b3b7;
        width: 100%;
        margin-left: 30px;
        top: 10px;
        position: relative;
    }
    .residence-date {
        display: block;
        font-size: 19px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: 0.32px;
        text-align: right;
        color: $brand-primary;
    }
}

.swal-add-product-service, .swal-change-stay-dates {
    width: 650px !important;

    .swal2-html-container {
        margin: 0 !important;
        text-align: left !important;
    }
}

.wrap-add-product-service {
    //display: none;

    &.new-room {
        .main-content {
            .head {
                label {
                    font-size: 27px;
                    display: block;
                    text-align: left;
                }
            }
        }
    }
    &.show {
        display: block;
    }

    .main-content {
        position: relative;
        width: 100%;

        .head {
            background-color: #f9fafd;
            height: 91px;
            margin: 0 0 25px;
            padding: 23px 20px 21px 36px;
            width: 100%;

            label {
                color: $brand-primary;
                font-family: Cormorant;
                font-size: 34px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.21;
                letter-spacing: normal;
                text-align: left;
            }
            button {
                background-color: transparent;
                background-image: url("/img/close.svg");
                background-size: 100% auto;
                border: 0;
                width: 15px;
                height: 15px;
                right: 32px;
                top: 32px;
                position: absolute;
            }
        }

        .wrap-search {
            position: relative;
            margin: 0 auto;
            min-height: 82px;
            width: 435px;
            z-index: 2;

            &.pressed {
                .search-patient {
                    background-image: url("/img/close.svg");
                }

                .wrap-input {
                    input {
                        width: 100%;
                    }
                }
            }

            .search-patient {
                height: 56px;
                right: 0;
                z-index: 1;
            }

            .wrap-input {
                top: 10px;
                position: relative;
                height: 45px;

                input {
                    color: $brand-primary;
                    width: 5%;
                    height: 56px;
                    padding: 15px 20px;
                    border-radius: 4px;
                    border: solid 1px #d9dce2;
                    float: right;
                    transition: all 0.5s;
                }
            }

            .wrap-result {
                background-color: white;
                border-top: solid 1px #d9dce2;
                border-left: solid 1px #d9dce2;
                border-right: solid 1px #d9dce2;
                top: 65px;
                position: absolute;
                width: 100%;

                .item {
                    padding: 15px;
                    height: 56px;
                    border-bottom: solid 1px #d9dce2;
                    width: 100%;

                    label {
                        display: block;
                        cursor: pointer;
                        font-size: 19px;
                        span {
                            display: inline-block;
                            font-size: 19px;
                            font-weight: bold;
                            float: right;
                        }
                    }
                }
            }
        }

        .body {
            position: relative;
            margin: 0 auto;
            width: 435px;

            label {
                display: block;
                font-family: Mukta;
                font-size: 19px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.83;
                letter-spacing: 0.24px;
            }
            input {
                color: $brand-primary;
                width: 100%;
                height: 56px;
                padding: 15px 20px;
                border-radius: 4px;
                border: solid 1px #d9dce2;

                &.error {
                    border: 1px solid red;
                }
            }
        }

        .wrap-error {
            label {
                display: block;
                font-size: 12px;
                color: red;
                margin: 20px auto 20px;
                text-align: center;
                opacity: 0;

                &.show {
                    opacity: 1;
                }
            }
        }

        .wrap-buttons {
            position: relative;
            margin: 30px auto;
            width: 435px;

            .cancel {
                color: $brand-primary;
                width: 200px;
                height: 57px;
                padding: 16px 20px;
                background-color: transparent;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 5.7px;
                text-transform: uppercase;
                border: 0;
            }
            .add-item, .save-change-room {
                background-color: $brand-primary;
                border-radius: 4px;
                color: white;
                width: 200px;
                height: 57px;
                padding: 16px 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 5.7px;
                text-transform: uppercase;
                font-size: inherit;
                border: 0;
            }
        }
    }

}

.wrap-payment {

    &.show {
        display: block;
    }

    .main-content {
        position: relative;
        width: 100%;

        .head {
            background-color: #f9fafd;
            height: 91px;
            margin: 0 0 25px;
            padding: 23px 20px 21px 36px;
            width: 100%;

            label {
                color: $brand-primary;
                font-family: Cormorant;
                font-size: 34px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.21;
                letter-spacing: normal;
                text-align: center;
                display: block;
            }
            button {
                background-color: transparent;
                background-image: url("/img/close.svg");
                background-size: 100% auto;
                border: 0;
                width: 15px;
                height: 15px;
                right: 32px;
                top: 32px;
                position: absolute;
            }
        }

        .body {
            position: relative;
            margin: 0 auto;
            width: 90%;

            @include media-breakpoint-up(md) {
                width: 460px;
            }

            .w-line {
                border: 1px solid $brand-primary;
                box-shadow: inset 0 0 3px #447167;
                padding-bottom: 20px;
                padding-top: 20px;
                position: relative;
                border-radius: 10px;
                //width: 100%;
            }

            label {
                display: block;
                font-family: $fontSite;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.83;
                letter-spacing: 0.24px;

                &.error {
                    color: red;
                }
                &.success {
                    color: green;
                }

                &.title {
                    font-size: 32px;
                }

                &#partial_payment {
                    margin: 0 auto;
                    width: 120px;
                }
            }
            select {
                background: url("/img/arrow-down.svg") transparent no-repeat 95% center;
                height: 56px;
                border-radius: 0;
                border-bottom: solid 1px $brand-primary;
                border-left: 0 solid;
                border-right: 0 solid;
                border-top: 0 solid;
                color: $brand-primary;
                -moz-appearance: none;
                -ms-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                font-size: 13px;
                padding-left: 20px;
                text-transform: uppercase;
                letter-spacing: 6.4px;
                text-align: left;
                width: 100%;

                &.error {
                    color: red;
                    border-bottom: solid 1px red;
                }
            }

            input[type=checkbox] {
                border-radius: 5px;
                border: 1px solid $brand-primary;
                height: 40px;
                margin: 0 auto 20px;
                width: 40px;
            }
            input[type=file] {
                font-size: 13px;
            }

            .wrap-id, .wrap-partial {
                margin-top: 20px;
            }

            input {
                color: $brand-primary;
                width: 100%;
                height: 56px;
                padding: 15px 20px;
                border-radius: 4px;
                border: solid 1px #d9dce2;
                margin-bottom: 15px;
            }

            .wrap-products {
                display: flex;
                flex-direction: column;
                align-items: baseline;
                margin-top: 20px;
                background-color: #f0f0f0;
                padding: 12px;
                border-radius: 10px;

                .product-service {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px;
                    width: 100%;
                    border: 1px solid;
                    border-radius: 10px;
                    margin-bottom: 10px;

                    &:hover {
                        background-color: #3b3d41;
                        color: white;

                        .product-price {
                            color: white;
                        }
                    }

                    .product-name {
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.8;
                        letter-spacing: 0.32px;
                        display: flex;
                        align-items: center;
                        gap: 15px;

                        small {
                            color: $brand-primary;
                            font-weight: 700;
                            font-size: .775em;
                        }

                        input[type=checkbox] {
                            height: 20px;
                            width: 20px;
                            margin: 0;
                        }
                    }

                    .product-price {
                        color: #447167;
                        text-align: right;
                        font-size: 18px;
                    }
                }

            }
        }

        .wrap-error {
            label {
                display: block;
                font-size: 12px;
                color: red;
                margin: 20px auto 20px;
                text-align: center;
                opacity: 0;

                &.show {
                    opacity: 1;
                }
            }
        }

        .wrap-buttons {
            position: relative;
            margin: 30px auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 435px;

            .cancel, .lineBtn {
                color: $brand-primary;
                width: 200px;
                height: 57px;
                padding: 16px 20px;
                background-color: transparent;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 5.7px;
                text-transform: uppercase;
                border: 0;
            }
            .do-payment, .do-add-discount, .do-add-product-discount, .btn-lightbox, .do-add-account-discount {
                background-color: $brand-primary;
                border-radius: 4px;
                color: white;
                width: 200px;
                height: 57px;
                padding: 16px 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 5.7px;
                text-transform: uppercase;
                border: 0;
            }
        }
    }

}

.wrap-change-stay-date {

    &.show {
        display: block;
    }

    .main-content {
        position: relative;
        width: 100%;

        .head {
            background-color: #f9fafd;
            height: 91px;
            margin: 0 0 25px;
            padding: 23px 20px 21px 36px;
            width: 100%;

            label {
                color: $brand-primary;
                font-family: Cormorant;
                font-size: 34px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.21;
                letter-spacing: normal;
                text-align: left;
            }
            button {
                background-color: transparent;
                background-image: url("/img/close.svg");
                background-size: 100% auto;
                border: 0;
                width: 15px;
                height: 15px;
                right: 9px;
                top: 8px;
                position: absolute;
            }
        }

        .body {
            position: relative;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 580px;

            &.change-date {
                width: 485px;
            }

            .wrap-item {
                align-items: center;
                width: 100%;
            }

            label {
                display: block;
                font-family: Mukta;
                font-size: 19px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.83;
                letter-spacing: 0.24px;

                strong {
                    color: $brand-primary;
                    font-weight: 700;
                }
            }
            input {
                color: $brand-primary;
                width: 100%;
                height: 56px;
                padding: 15px 20px;
                border-radius: 4px;
                border: solid 1px #d9dce2;

                &.error {
                    border: 1px solid red;
                }
            }

            label {
                &.error {
                    color: red;
                    font-size: 13px;
                    text-align: left;
                    margin-top: 10px;
                }
            }
        }

        .body-row {
            label {
                display: block;
                font-family: Mukta;
                font-size: 19px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.83;
                letter-spacing: 0.24px;
            }
            input {
                color: $brand-primary;
                width: 100%;
                height: 56px;
                padding: 15px 20px;
                border-radius: 4px;
                border: solid 1px #d9dce2;
                margin-bottom: 35px;

                &.error {
                    border: 1px solid red;
                }
            }

            label {
                &.error {
                    color: red;
                    font-size: 13px;
                    text-align: left;
                    margin-top: 10px;
                }
            }
        }

        .wrap-error {
            label {
                display: block;
                font-size: 12px;
                color: red;
                margin: 20px auto 20px;
                text-align: center;
                opacity: 0;

                &.show {
                    opacity: 1;
                }
            }
        }

        .wrap-buttons {
            position: relative;
            margin: 30px auto;
            width: 435px;

            .cancel {
                color: $brand-primary;
                width: 200px;
                height: 57px;
                padding: 16px 20px;
                background-color: transparent;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 5.7px;
                text-transform: uppercase;
                border: 0;
            }
            .green-btn {
                background-color: $brand-primary;
                border-radius: 4px;
                color: white;
                width: 200px;
                height: 57px;
                padding: 16px 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 5.7px;
                text-transform: uppercase;
                border: 0;
            }
        }
    }

}

.float-right {
    float: right;
}

.patient-video {
    position: relative;

    .container {
        width: 100%;

        .video-content {
            text-align: center;

            img {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 48%;
                cursor: pointer;
            }

            video {
                width: 100%;
            }
        }
    }
}

.wrap-error {
    color: red;
}

.btnInSwal {
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 16px 20px;
    border-radius: 4px;
    letter-spacing: 3px;
    text-transform: uppercase;
    background-color: $brand-primary;
    border: 0;
    font-size: 11px;
    color: white;

    &.btnSmall {
        padding: 7px 12px;
        height: 42px;
        width: 133px;
    }
}

.big-image {
    cursor: pointer;
}

.latidos {
    animation: latidos 1s infinite;
}

.wrap-thumbnail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}
.image-thumbnail {
    border: 1px solid $brand-primary;
    padding: 5px;
    border-radius: 4px;
    margin: 10px;
    text-align: center;
}
.image-thumbnail:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@keyframes latidos {
    from { transform: none; }
    50% { transform: scale(1.4); }
    to { transform: none; }
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
#time-slots {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;

    &.w-item {
        height: 800px;
    }
}
.time-slot, .food-slot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 185px;

    span {
        margin-left: 15px;
        margin-right: 15px;
    }

    button {
        //margin-left: 15px;
    }
}

#body-parts-slots {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;

    &.w-item {
        height: auto;
    }
}
.body-parts, .food-slot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 280px;

    span {
        margin-left: 15px;
        margin-right: 15px;
    }

    button {
        //margin-left: 15px;
    }
}

.no-margin {
    margin: 0;
}

.medical-print {
     header {
        img {
            display: block;
            margin: 0 auto 20px;
            width: 100px;
        }

        .wrap-address {
            label {
                display: block;
                text-align: center;
            }
        }
     }

    .wrap-dr {
        label {
            color: $brand-primary;
            font-size: 19px;
            font-weight: 700;
            display: block;
            text-align: center;
        }
        span {
            font-size: 15px;
            font-weight: normal;
            display: block;
            text-align: center;
            margin-top: 15px;
        }
    }
}
