.mntl-dash-therapies {
    display: flex;
    flex: 1;
    width: 100%;

    @include media-breakpoint-up(lg) {
        width: 86%;
    }
    @include media-breakpoint-up(xl) {
        width: 88%;
    }

}
.main-wrap-therapy-panel {
    width: 100%;

    @include media-breakpoint-up(lg) {
        width: 89%;
    }

    &.paciente {
        @include media-breakpoint-up(lg) {
            width: 86%;
        }
        @include media-breakpoint-up(xl) {
            width: 88%;
        }
    }
}
#catalog-therapies {
    overflow: hidden;
    width: 100%;
        ul {
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                margin-right: 50px;
                border-bottom: 2px solid transparent;
                padding: 10px 0;
                a {
                    color: #b0b3b7;
                    padding: 10px;
                    margin: 0;
                }
                &.active, &:hover {
                    border-bottom: 2px solid $brand-primary;
                    a {
                        border-right: 0;
                        color: $brand-primary;
                    }
                }
            }
    }
    .nav-terapias {
        padding-top: 60px;
        padding-left: 100px;
        // padding-bottom: 20px;
    }
    .bg-gray {
        background-color: #f9fafd;
        padding: 50px 0;
        min-height: 90vh;
        // display: flex;
        // flex-direction: row;
        // flex-wrap: nowrap;
        // width: 100%;
    }

    .slider {
        margin: 0 20px;
        .slick-prev {
            left: -10px;
        }
        .slick-next {
            right: -10px;
        }
    }
}

.single-therapy {
    position: relative;
    width: 320px;
    background-color: white;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    // margin: 20px;

    .bg {
        width: 100%;
        padding-bottom: 75%;
        display: flex;
        justify-content: center;
        position: relative;
        cursor: pointer;

        .bg-black{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.2);
        }
        img {
            position: absolute;
            top: 50%;
            margin-top: -30px;
        }
    }
    .content-therapy {
        padding: 25px 30px;
        h2 {
            color: $brand-primary;
            font-size: 34px;
            font-family: $fontTitle;
            line-height: 36px;
        }
        p {
            color: $textcolor;
            font-size: 14px;
        }
        .buttons {
            display: flex;
            flex-direction: row;
            a {
                font-size: 13px;
                font-weight: 200;
                width: 50%;
                height: 46px;
                display: flex;
                border-radius: 4px;
                justify-content: center;
                text-transform: uppercase;
                align-items: center;
                letter-spacing: 3px;
            }
            .btn1 {
                color: $brand-primary;
            }
            .btn2 {
                background-color: $brand-primary;
                color: white;
            }
        }
    }
}

.slick-slide {
    padding: 0 14px;
}


#catalog-therapies-detail {
    overflow: hidden;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .content-detail {
        padding: 80px 50px 50px;
        color: #3b3d41;
    }
    a.back {
        color: $brand-primary;
        letter-spacing: 5.7px;
        display: block;
        margin-bottom: 24px;
        img {
            margin-right: 18px;
        }
    }
    h1 {
        color: $brand-primary;
        font-size: 60px;
        font-family: $fontTitle;
        line-height: 63px;
    }
    p {
        line-height: 26px;
        font-size: 16px;
        white-space: pre-line;
    }
    .btn-green {
        margin-top: 20px;
        display: inline-block;
    }
    .wrap-carousel {
        display: flex;
        justify-content: center;
    }
    .carousel {
        margin-top: 25px;
    }
}

.options-modal.buttons {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 90%;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;

    button {
        border: 0;
        width: 50%;
        height: 46px;
        display: flex;
        border-radius: 4px;
        justify-content: center;
        text-transform: uppercase;
        align-items: center;
        letter-spacing: 3px;
    }
    .btn1 {
        color: $brand-primary;
        background-color: white;
    }
    .btn2 {
        background-color: $brand-primary;
        color: white;
    }
}

.single-food {
    text-align: center;
    color: $brand-primary;
    margin-bottom: 40px;
    
    strong {
        text-transform: uppercase;
        font-size: 15px;
        letter-spacing: 5.7px;
        display: block;
        margin-bottom: 5px;
    }
    h2 {
        font-size: 36px;
        font-family: $fontTitle;
        line-height: 41px;
    }
    p {
        color: black;

        span {
            color: #a0aec0;
            font-size: 12px;
            font-weight: 600;
            display: block;
        }

    }
    a {
        background-color: $brand-primary;
        border: 0;
        color: white;
        display: block;
        height: 40px;
        font-size: 12px;
        text-transform: uppercase;
        padding-left: 10px;
        padding-right: 10px;
        letter-spacing: 5.7px;
        line-height: 40px;
        width: auto;
        &:hover {
            text-decoration: underline;
        }
    }
}

.w-100 {
    width: 100%;

    &-f {
        width: 100% !important;
    }
}

.form-control {
    height: auto;
}
