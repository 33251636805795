.tabs-calendar {
    ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;
        li {
            padding: 5px 20px 10px 15px;
            margin: 0 10px;
            &:hover, &.active {
                border-bottom: 2px solid white;
                a {
                    color: $brand-primary;
                }
            }
            a {
                color: $textcolor;
            }
        }
    }
}
.calendar {
    display: flex;
    margin-bottom: 100px;
    .wrap-hours {
        width: 10%;
    }
    #calendar {
        width: 90%;
    }
    .wrap-days {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .single-day {
            width: 33%;
            // border: 1px solid red;

            .header {
                text-align: center;
                height: 80px;
                color: $textcolor;
                display: flex;
                flex-direction: column;
                justify-content: center;
                // align-items: end;
            }
            &.today {
                background-color: #f9fafd;
                .header {
                    color: $brand-primary;
                }
            }

            .wrap-programacion {
                .item {
                    height: 80px;
                    border: solid 1px #d9dce2;
                    margin: 12px;
                    border-radius: 4px;
                    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.06);
                    padding: 10px 16px;
                    display: flex;
                    flex-direction: column;
                    .title-item {
                        color: $brand-primary;
                    }
                    .hour-defined {
                        color: $textcolor;
                        font-size: 13px;
                    }

                    &.food {
                        background-color: #f9fafd;
                        .title-item {
                            color: $textcolor;
                        }
                    }

                    &.extra {
                        background-color: $brand-primary;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-around;
                        gap: 20px;
                        position: relative;

                        a {
                            visibility: hidden;
                            font-size: 12px;
                            color: $textcolor;
                        }
                        &:hover {
                            a {
                                visibility: visible;
                                color: white;
                            }
                        }
                        .appointment-actions {
                            background-color: transparent;
                            position: absolute;
                            left: 0;
                            right: 0;
                            margin: auto;

                            &:hover {
                                background-color: $brand-primary;
                            }
                            a {
                                &:first-child {
                                    margin-right: 8px;
                                }
                            }
                        }


                        .wrap-therapy {
                            width: 100%;
                            position: relative;

                            &:before {
                                background-color: white;
                                position: absolute;
                                right: -10px;
                                top: 0;
                                content: "";
                                height: 100%;
                                width: 1px;
                            }
                            &:after {
                                background-color: white;
                                position: absolute;
                                left: -10px;
                                top: 0;
                                content: "";
                                height: 100%;
                                width: 1px;
                            }

                            .title-item {
                                color: white;
                                position: relative;

                                .conflict {
                                    background-color: #F11C31;
                                    position: absolute;
                                    border-radius: 50%;
                                    right: -14px;
                                    top: -7px;
                                    color: white;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 11px;
                                    height: 20px;
                                    width: 20px;
                                }
                            }
                            .hour-defined {
                                color: white;
                            }
                        }

                        &:hover {
                            box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.4);
                        }
                    }

                    &.appointment {
                        background-color: white;
                        a {
                            visibility: hidden;
                            font-size: 12px;
                            color: $textcolor;
                            &:hover {
                                color: $brand-primary;
                            }
                        }
                        &:hover {
                            a {
                                visibility: visible;
                            }
                        }
                        .appointment-actions {
                            a {
                                &:first-child {
                                    margin-right: 8px;
                                }
                            }
                        }
                    }

                    &.empty {
                        border: 0;
                        background-color: none;
                        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06);

                        a {
                            visibility: hidden;
                            font-size: 12px;
                            color: $textcolor;
                            &:hover {
                                color: $brand-primary;
                            }
                        }

                        &:hover {
                            a {
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }
    }
    .header-hours {
        height: 80px;
    }
    .hours {
        color: $textcolor;
        font-size: 12px;
        height: 80px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 12px 0;
    }
    .slick-slider .slick-arrow.slick-next {
        right: -25px;
        top: 45px;
    }
    .slick-slider .slick-arrow.slick-prev {
        left: -25px;
        top: 45px;
    }
}

.ReactModal__Content {
    padding: 0 !important;

    .basic-data {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.sweetalert-header {
    background-color: #f9fafd;
    padding: 23px 20px 18px 45px;
    text-align: left;
    .hours {
        font-size: 14px;
        color: $textcolor;
    }
    .terapia {
        color: $brand-primary;
        font-family: $fontTitle;
        font-size: 34px;
    }
}
.no-padding-sweet {
    .swal2-html-container {
        margin: 0 !important;
    }
    .swal2-styled.swal2-confirm,
    .swal2-styled.swal2-deny {
        background-color: $brand-primary;
        width: 400px;
        letter-spacing: 5.7px !important;
        text-transform: uppercase !important;
        padding: 16px 20px;
        margin-bottom: 15px;
    }
    .swal2-styled.swal2-confirm {
        margin-top: 20px;
    }
    .swal2-styled.swal2-deny {
        margin-bottom: 50px;
    }

    &.add-item {
        .swal2-html-container {
            // min-height: 350px;
            text-align: left;
        }
        .basic-data {
            display: flex;
            flex-direction: column;
            .cols {
                width: 50%;
                color: $textcolor;
                font-size: 15px;
                strong {
                    color: black;
                    font-size: 17px;
                }
                .select-width {
                    width: 100%;
                }
            }
        }
    }
}

.bg-green {
    background-color: $brand-primary;
    color: white !important;
    a {
        color: white !important;
    }

    .wrap-hours {
        span {
            color: #78a69c !important;
        }
    }
    h1 {
        color: white !important;
    }
}

.img-rounded {
    width: 90px;
    height: 90px;
    border-radius: 70px;
    object-fit: cover;
}
.txt-center {
    align-items: center;
    display: flex;
}
.swal2-actions {
    z-index: 0 !important;
}

.mntl-dash_item figure {
    margin-bottom: 0;
}

.customSelect {
    display: block;
    width: 100%;
    border-radius: 4px;
    border-color: hsl(0, 0%, 80%);
    height: 65px;
    padding-left: 5px;
    padding-right: 5px;
    color: hsl(0, 0%, 20%);
}
