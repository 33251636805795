.mntl-dash_history{
   &-hdr{
        background-color: #f9fafd;
        height: 100px;
        padding-left: 15px;
        padding-right: 15px;
        @include media-breakpoint-up(md){
            padding-left: 50px;
            padding-right: 50px;
        }
        .back{
            background: url('/img/back/back.svg') no-repeat left center;
            color: $brand-primary;
            font-family: $fontTitle;
            font-size: 30px;
            font-weight: 600;
            padding-left: 30px;

            &.no-img {
                background: none;
            }

            @include media-breakpoint-up(md){
                font-size: 34px;
                height: auto;
                width: auto;
            }
            &.history-main{
                width: 50px;
                height: 50px;
                span{
                    @include media-breakpoint-down(sm){
                        //display: none;
                    }
                }
            }
        }
        .mntl-dash-search{
            position: relative;
            @include media-breakpoint-down(sm){
                flex-grow: 1;
            }
            form{
                width: 100%;
            }
            input{
                background: white;
                border: solid 1px #eff1f4;
                border-radius: 4px;
                height: 50px;
                width: 100%;
                padding: 0 50px 0 12px;
                @include media-breakpoint-up(md){
                    width: 230px;
                }
                &:focus{
                    border-color: $brand-primary;
                    outline: none;
                }
            }
            button{
                background: transparent;
                border: none;
                cursor: pointer;
                width: 40px;
                height: auto;
                position: relative;
                margin-left: -40px;
            }
        }

        &.fixed {
            position: fixed;
            z-index: 2;
            width: 100%;
            padding: 0 20px 0 120px;
            top: 0;
            left: 0;
        }
   }
}
.margin-bar {
    padding-top: 125px;
}
.history-container{
    max-width: 1100px;
    margin: 0 auto;
}
.mntl-dash_history-data{
   padding: 50px 15px 0 15px;
    @include media-breakpoint-up(md){
        padding: 70px 0 0 0;
    }
    .history-data_titles{
        display: none;
        @include media-breakpoint-up(lg){
            display: flex;
            flex-wrap: wrap;
        }
        >span{
            color:#b0b3b7;
            font-size: 19px;
            margin-bottom: 15px;
        }
    }
    .history-data_name{
       @include media-breakpoint-up(md){
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 40px;
       }
    }
    .history-data_date{
        @include media-breakpoint-up(md){
            flex: 0 0 40.66666667%;
            max-width: 40.66666667%;
       }
    }
    .history-data_stay{
        @include media-breakpoint-up(md){
            flex: 0 0 8.33333333%;
            max-width: 8.33333333%;
       }
    }
}
.history-data_inf {
    border-radius: 4px;
    border: solid 1px #eff1f4;
    margin-bottom: 50px;

    .history-data_inf-group {
        .head {
            padding: 20px;
            background-color: $brand-primary;
            color: white;
            font-weight: 700;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
    }

    &.coupons {
        margin: 0 auto;
        width: 86%;
    }

    &.no-border {
        border: 0;
    }

    &-item{
        border-bottom: 1px solid #eff1f4;
        color:#3b3d41;
        padding: 20px 15px;
        display: block;
        @include media-breakpoint-down(sm){
            >span{
                display: block;
            }
            .history-data_stay{
                color:$brand-primary;
            }
        }
        @include media-breakpoint-up(md){
            padding: 18px;
            display: flex;
            flex-wrap: wrap;
        }
        &:hover{
            background: #f9fafd;
            color:$brand-primary;
            text-decoration: none;
        }

        a {
            font-weight: bold;
            color: $brand-primary;
            padding: 0 5px;
            &:hover {
                text-decoration: underline;
            }

        }
    }
}
.mntl-dash_paginator{
    padding-left: 15px;
    padding-right: 15px;
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        list-style-type: none;
        padding-left: 0;
        @include media-breakpoint-up(md){
            justify-content: flex-end;
        }
        li{
            &.prev a,
            &.next a{
                border-radius: 4px;
                border: solid 1px #eff1f4;
            }
            &.prev a{
                background: url('/img/back/prev-icon.svg') no-repeat center center;
            }
            &.next a{
                background: url('/img/back/next-icon.svg') no-repeat center center;
            }
            &.active {
                span {
                    display: flex;
                    color: $brand-primary;
                    text-decoration: none;
                    height: 50px;
                    width: 50px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            a{
                color:#d9dce2;
                font-size: 16px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                width: 50px;
                &.active,
                &:hover{
                   color: $brand-primary;
                   text-decoration: none;
                }
            }
        }
    }
}

%scrollHiden{
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

%days{
    color:#b0b3b7;
    padding-bottom: 10px;
    position: relative;
    transition: color 250ms ease;
    overflow: hidden;
    display: inline-flex;
    min-width: 62px;

    &.medical-report {
        &::after{
            opacity: 0;
            background: $brand-primary;
            content: "";
            height: 2px;
            width: 50%;
            position: relative;
            bottom: -27px;
            transform: translateX(-101%);
            transition: transform 250ms ease;
        }
    }

    &:last-child{
        padding-right: 0;
    }
    &::after{
        opacity: 0;
        background: $brand-primary;
        content: "";
        height: 2px;
        width: 45%;
        position: absolute;
        bottom: 0;
        transform: translateX(-101%);
        transition: transform 250ms ease;
        //left: 0;
        //right: 0;
        //margin-left: auto;
        //margin-right: auto;
    }
    &:hover,
    &.current{
        text-decoration: none;
        &::after{
            opacity: 1;
            //transform: translateX(0);
        }
    }
}
.slick-list {
    text-align: center;
    width: 100%;
}
.mmtl-history_days{
    @extend %scrollHiden;

    &::-webkit-scrollbar {display: none; }
    &.date {
        overflow-x: unset;
        overflow-y: unset;
        a {
            &:hover,
            &.current{
                text-decoration: none;
                &::after{
                    transform: translateX(60%);
                }
            }
        }
    }
    a{
        @extend %days;
        padding:0 10px 10px;
        @include media-breakpoint-up(lg){
            padding:0 20px 10px;
        }
        @include media-breakpoint-up(xl){
            padding:0 30px 10px;
        }
    }

    .wrap-img {
        height: 50px;
        width: 50px;
        margin-left: 21px;
        top: -8px;
        position: relative;
        img {
            display: block;
            height: auto;
            border-radius: 50%;
            width: 100%;
        }
    }
}

.mntl-history_detHdr{
    color:#b0b3b7;
    padding: 0;
    list-style-type: none;
    margin-bottom: 50px;

    li {

        &.current {
            color: red;
            list-style: disc;
        }

        a {
            color:#b0b3b7;

            span {
                color:$brand-primary;
            }
        }
        span{
            color:$brand-primary;
        }

    }

}

.detail{
    padding-top: 70px;
    @include media-breakpoint-up(md){
        display: flex;
        flex-wrap: wrap;
    }
}

.mntl-history_detData{
    @include media-breakpoint-up(md){
        padding-right: 30px;
    }
    label {
        color:$brand-primary;
        font-size: 14px;
        letter-spacing: 5.7px;
        margin-bottom: 15px;
        display: block;
        text-transform: uppercase;
    }
    h3{
        color:$brand-primary;
        letter-spacing: 5.7px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
    p{
        line-height: 1.63;
        margin-bottom: 30px;
    }
}

.mntl-history_detSpecs{
    border: solid 1px #eff1f4;
    margin-bottom: 50px;

    .day-title {
        font-size: 16px;
        color: white;
        letter-spacing: 3.7px;
        margin-bottom: 15px;
        text-transform: uppercase;
        padding-left: 30px;
        margin-top: 30px;
        display: block;
        background-color: $brand-primary;
        height: 40px;
        line-height: 40px;
        font-weight: 800;
    }

    .mmtl-history_daysSmall{
        border-bottom: 1px solid #d9dce2;
        padding: 19px 30px 0 50px;
        list-style-type: none;
        @extend %scrollHiden;
        &::-webkit-scrollbar {display: none; }

        a{

            @extend %days;
            margin-right: 30px;
        }
    }
    .mmtl-history_daysSmallMedical{
        border-bottom: 1px solid #d9dce2;
        padding: 19px 30px 0 50px;
        list-style-type: none;
        @extend %scrollHiden;
        &::-webkit-scrollbar {display: none; }

        a{

            @extend %days;
            margin-right: 30px;
        }
    }

    .mntl-history_specs{
        padding: 30px;

        &.dark {
            background-color: #0c0c0c0c;
        }
        label{
            font-size: 14px;
            color: #b0b3b7;
            display: block;
        }

        .therapies-days {
            display: none;

            &.show {
                display: block;
            }
        }

        span{
            background: #f9fafd;
            color:$brand-primary;
            font-size: 16px;
            height: 50px;
            line-height: 50px;
            display: block;
            padding: 0 20px;
            position: relative;
            margin-bottom: 10px;
        }

        p {
            color: black;
            font-size: 16px;
        }
    }
}

.autoGenerateCouponCode {
    background-image: url("/img/arrows-around.webp");
    background-position: center center;
    background-size: 100% auto;
    height: 40px;
    position: absolute;
    display: inline-block;
    top: 40px;
    left: 54%;
    opacity: 1;
    width: 40px;
}

.coupon-item {
    background-color: $brand-primary;
    border-radius: 10px;
    height: 550px;
    padding-top: 20px;
    margin-right: 20px;
    position: relative;
    width: 250px;

    &.disabled {
        opacity: 0.5;
    }

    box-shadow:
        2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
        6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
        12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
        22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
        41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
        100px 100px 80px rgba(0, 0, 0, 0.07);

    .wrap-img {
        background-color: $brand-secondary;
        border-radius: 50%;
        margin: 0 auto 15px auto;
        display: flex;
        height: 130px;
        width: 130px;

        img {
            margin: 0 auto;
            width: 85%;
        }
    }

    .title {
        color: white;
        font-size: 60px;
        font-weight: 600;
        display: block;
        text-align: center;
        line-height: 35px;
        padding-left: 0;

        span {
            font-size: 25px;
        }
    }

    .name {
        font-family: $fontSite;
        color: white;
        display: block;
        font-size: 25px;
        text-align: center;
        line-height: 25px;
        width: 85%;
        margin: 18px auto;
    }

    .small {
        display: block;
        color: white;
        text-align: center;
        margin-bottom: 15px;
    }

    .date {
        display: block;
        color: white;
        text-align: center;
        margin-bottom: 15px;
        height: 20px;

        strong {
            color: white;
            font-size: 18px;
            font-weight: 600;

            &.expired {
                color: orange;
            }
        }
    }

    .code {
        font-size: 20px;
        display: block;
        margin: 0 auto;
        text-align: center;
        line-height: 0;
        position: relative;
        width: 85%;

        i {
            bottom: 15px;
            display: block;
            font-style: normal;
            left: 0;
            text-align: center;
            position: absolute;
            width: 100%;
        }

        img {
            width: 100%;
        }
    }

    .wrap-administration {
        background-color: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        color: #447167;
        height: 40px;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        a {
            color: $brand-primary;
            display: block;
            text-align: center;
            width: 120px;
        }
    }
}
