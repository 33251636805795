aside{
    background: $brand-primary;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    z-index: 9;

    &.menu-paciente {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 140px;
        }
        nav {
            display: flex;
            justify-content: space-between;
            padding: 0;
            width: 75%;
            gap: 13px;

            @include media-breakpoint-up(lg) {
                display: block;
                width: 100%;
                position: fixed !important;
                top: 50%;
                margin-top: -125px;
            }

            a {
                color: rgba(255,255,255, 0.4) !important;
                font-size: 16px !important;
                display: block;
                height: 65px;
                border-top: 1px solid #3a645b;
                border-bottom: 1px solid #3a645b;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                &.current {
                    color: white !important;
                }
            }
        }
    }
    @include media-breakpoint-up(lg){
        width: 100px;
        flex-direction: column;
        padding: 35px 0;

        &.menu-paciente, &.menu-paciente nav {
            width: 140px;
        }
    }
}

nav{
    padding: 0 !important;

    @include media-breakpoint-up(lg){
        text-align: center;
        width: 100%;
        position: fixed !important;
        top: 50%;
        margin-top: -125px;
        width: 100px;
    }
    img{
        opacity: .5;
        transition: opacity 250ms ease;
    }
    a{
        position: relative;
        margin: 0 15px;

        @include media-breakpoint-up(lg){
            display: block;
            margin:0 0 40px 0;
            width: 100%;
        }

        &::after{
            background: transparent;
            content: "";
            right: auto;
            left: 0;
            bottom: -8px;
            height: 5px;
            width: 100%;
            position: absolute;
            transition: background 250ms ease;

            @include media-breakpoint-up(lg) {
                height: 100%;
                width: 5px;
                top: 0;
                left: auto;
                right: 0;
                bottom: auto;
            }

        }

        &:hover,&.current{
            img{
                opacity: 1;
            }
            &::after{
                background: #74a59a;
            }
        }

    }

    #menu-config {
        position: relative;
        display: inline-block;
        margin: 0 15px;
        
        @include media-breakpoint-up(lg) {
            height: 50px;
            width: 100%;
            display: block;
        }

        &::after{
            background: transparent;
            content: "";
            height: 100%;
            width: 5px;
            transition: background 250ms ease;
            position: absolute;
            top: 0;
            right: 0;
        }
        &.current {
            &::after{
                background: #74a59a;
            }
        }

        .submenu-config {
            padding: 10px 0;
            transition: opacity 0.5s ease;
            position: absolute;
            overflow: hidden;
            width: 0;
            z-index: 8;
            left: -68px;
            top: 50px;
            background-color: gray;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            opacity: 0;

            @include media-breakpoint-up(lg) {
                left: 75px;
                padding-left: 15px;
                background: $brand-primary;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                border-top-left-radius: 0;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 10px;
                top: -100px;
                max-height: 500px;
                overflow-y: scroll;
            }

            a {
                color: white;
                margin-bottom: 10px;
                display: block;

                @include media-breakpoint-up(lg) {
                    text-align: left;
                    padding-left: 20px;
                }

                &.current, &:hover {
                    background-color: #204c42;
                }
            }
        }
        &:hover {
            .submenu-config {
                width: 250px;
                opacity: 1;
            }
        }
    }

}
.wrap-center {
    width: 465px;

    @media (min-width: 1400px) {
        width: 500px;
    }
    
    @media (min-width: 1600px) {
        width: 545px;
    }
    
    @media (min-width: 1800px) {
        width: 630px;
    }

    .central-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 25px;

        @media (min-width: 1600px) {
            justify-content: space-around;
            padding-top: 45px;
        }

        .item {
            text-align: center;
            width: 30%;

            @media (min-width: 1800px) {
                width: auto;
            }

            a {
                color: #b0b3b7;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                letter-spacing: 0.32px;
                text-align: center;
                display: inline;
                position: relative;
                width: auto;

                &.active {
                    color: $brand-primary;

                    &:after {
                        background-color: $brand-primary;
                        content: '';
                        height: 2px;
                        position: absolute;
                        bottom: -12px;
                        left: 0;
                        width: 100%;
                        right: 0;
                        margin: 0 auto  ;
                    }
                }
            }

        }
    }

}

.dropdown-menu-photo {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 0;
    z-index: 1000;
    margin-left: auto;
    display: none;
    margin-right: auto;
    width: 200px;
}