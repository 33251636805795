.mntl-dash{
    max-width: 1100px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 45px;

    @media (min-width: 1800px) {
        max-width: 1600px;
    }
    @media (min-width: 2500px) {
        max-width: 1900px;
    }

    @media (min-width: 1800px) {
        .col-xl-3 {
            flex: 0 0 auto;
            width: 20%;
        }
    }

    &_itemButton{
        height: 100px;
    }
    &_itemWrap{
        height: 290px;
        margin-bottom: 40px;

        &.Enfermera, &.Recepcion {
            height: 370px;
        }

        &.Cocina {
            height: 300px;
            max-width: 600px;

            @include media-breakpoint-up(lg) {
                height: 305px;
            }

            @media (min-width: 1800px) {
                height: 360px;
            }

            .mntl-dash_item-hdr {
                margin-bottom: 7px;
                .item-number {
                    font-size: 30px;
                    width: 65px;
                    height: 45px;
                }
            }

            .mntl-dash_item-data {
                ul {
                    padding-left: 10px;

                    li {
                        .item-name {
                            text-align: left;
                            font-size: 24px;

                            label {
                                font-size: 45px;
                            }
                        }
                        .item-comment {
                            text-align: left;
                            font-size: 20px;
                            line-height: 23px;
                            color: black;
                            width: 45%;

                            &.where-to-eat {
                                font-size: 20px;
                                color: $brand-primary;
                                font-weight: bold;
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }

        &.Therapy {
            height: 300px;
            max-width: 600px;

            @include media-breakpoint-up(lg) {
                height: 305px;
            }

            @media (min-width: 1800px) {
                height: 360px;
            }

            .mntl-dash_item-hdr {
                margin-bottom: 7px;
                .item-number {
                    font-size: 30px;
                    width: 65px;
                    height: 45px;
                }
            }

            .mntl-dash_item-data {
                ul {
                    padding-left: 10px;

                    li {
                        .item-name {
                            text-align: left;
                            font-size: 33px;
                        }
                        .item-comment {
                            text-align: left;
                            font-size: 20px;
                            line-height: 23px;
                            color: black;
                            width: 45%;

                            &.where-to-eat {
                                font-size: 20px;
                                color: $brand-primary;
                                font-weight: bold;
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.dFlex{
        flex-direction: column;
        justify-content: space-between;
    }
}
.mntl-dash-ftr{
    padding: 0 15px;
    a{
        color:$brand-primary;
        font-size: 15px;
        letter-spacing: 5.7px;
        text-transform: uppercase;
    }
}
.mntl-dash_item{
    height: 100%;
    margin-left: 20px;
    //cursor: pointer;
    &:not(.item_empty){
        background: white;
        border-radius: 4px;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.06);
        padding-bottom: 20px;
        position: relative;
        .item-number{
            color:$brand-primary;
        }
    }
    &-hdr{
        margin-bottom: 16px;
        .item-number{
            background: #f9fafd;
            font-size: 30px;
            width: 70px;
            height: 64px;
            text-align: center;
            @include media-breakpoint-up(md){
                font-size: 40px;
            }
        }
    }
    .dash-options{
        background: transparent;
        border: none;
        text-align: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    &-data{
        padding: 0 28px;
        span{
            color:#b0b3b7;
            font-size: 17px;
            display: block;
        }
        .item-name{
            color:$brand-primary;
            font-family: $fontTitle;
            font-size: 16px;
            font-weight: 600;

            &.dashboard {
                font-size: 30px;
                min-height: 57px;
                margin-bottom: 20px;
                line-height: 28px;
            }
            &.big {
                font-size: 30px;
            }

            &.therapy-name {
                font-family: $fontSite;
                font-size: 30px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.18;
                letter-spacing: 0.44px;
                text-align: left;
            }
            &.therapy-description {
                color: $brand-secondary;
                font-size: 25px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.18;
                letter-spacing: 0.44px;
                margin-top: 15px;
                text-align: left;
            }
            &.therapy-patient-name {
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #b0b3b7;
                font-size: 38px;
            }
        }
    }
    &-ftr,
    &-data,
    &-payed{
        padding: 0 28px;
    }
    &-payed{
        height: 30px;
    }
    .item-ftr{
        color:#b0b3b7;
        font-size: 17px;
    }
    .item-total{
        color:$brand-primary;
        font-size: 18px;
    }

    .view-profile {
        &.diabetic {
            .img-thumbnail {
                border: 2px $diabeticColor solid;
            }
        }
        &.colema {
            .img-thumbnail {
                border: 2px $colemaColor solid;
            }
        }
    }
    .img-thumbnail {
        position: absolute;
        left: 0;
        right: 0;
        top: 10px;
        margin: 0 auto;
        border-radius: 50%;
        object-fit: cover;
        height: 60px;
        width: 60px;
    }

    .dash-options_menu{
        background-color: #fff;
        border-radius: 4px;
        border: solid 1px #eceff5;
        box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 10px;
        right: 20px;
        left: 20px;
        display: none;
        overflow: hidden;
        z-index: 3;
        &.show{
            display: block;
        }

        span{
            background-color: white;
            color:$brand-primary;
            position: absolute;
            font-size: 25px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            width: 42px;
            top: 10px;
            right: 0;
            cursor: pointer;
        }
        a{
            background: white;
            border-bottom: 1px solid rgba(#d9dce2,0.5);
            color:$brand-primary;
            font-size: 14px;
            display: block;
            padding: 15px 20px;
            transition: all 250ms ease;
            &:hover{
                background: $brand-primary;
                color:white;
            }

        }
    }

    &.item_empty{
        border: solid 1px #d9dce2;
        background-color: #f9fafd;
        position: relative;

        a {
            display: block;
            height: auto;
            width: 100%;
        }

        .item-number{
            color:#d9dce2;
        }

        svg{
            circle{
                fill: #EFF1F4;
                opacity: .5;
                stroke:#EFF1F4;
            }
            path{
                fill:#447167;
            }
        }

        .item_empty_add{
            flex-direction: column;
            align-items: center;
            color:#d9dce2;
            font-size: 18px;
        }

        .item-add{
            cursor: pointer;
            &:hover{
                svg{
                    circle{
                        fill: $brand-primary;
                        opacity: 1;
                        stroke:$brand-primary;
                    }
                    path{
                        fill:white;
                    }
                }
            }
        }

        .wrap-disable-room {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;

            a {
                display: flex;
                align-items: center;
                font-size: 17px;
                color: #3b3d41;
                height: 30px;
                justify-content: center;
                background-color: darkgray;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    &.occupied, &.agendado {
        background: $brand-primary;
        .item-number{
            background: #4d7b72;
            color: white;
        }
        .item-name,
        .mntl-dash_item-data span,
        .item-ftr,
        .item-total{
            color:white;
        }
        .mntl-dash_item-data span:not(.item-name),
        .item-ftr{opacity: 0.5;}
    }

    &.agendar{
        &:hover{
            background: $brand-primary;
            .item-number{
                background: #4d7b72;
                color: white;
            }
            .item-name,
            .mntl-dash_item-data span,
            .item-ftr,
            .item-total{
                color:white;
            }
            .mntl-dash_item-data span:not(.item-name),
            .item-ftr{opacity: 0.5;}
        }
        figure {
            margin: 0;
            text-align: center;
            height: 220px;
            position: absolute;
            right: 0;
            width: 220px;
            bottom: 0;

            img {
                border-radius: 50%;
                height: auto;
                width: 100%;
            }
        }
    }
    &.comida{
        &:hover{
            background: #d2bda9;
            .item-number{
                background: #d8c2ad;
                color: white;
            }
            .item-name,
            .mntl-dash_item-data span,
            .item-ftr,
            .item-total{
                color:white;
            }
            .mntl-dash_item-data span:not(.item-name),
            .item-ftr{opacity: 0.5;}
        }
    }
    &.pagado{
        &:hover{
            background: #1f3666;
            .item-number{
                background: #233e76;
                color: white;
            }
            .item-name,
            .mntl-dash_item-data span,
            .item-ftr,
            .item-total{
                color:white;
            }
            .mntl-dash_item-data span:not(.item-name),
            .item-ftr{opacity: 0.5;}
        }
    }
    &.over-date{
        &:hover{
            background: #FF5733;
            .item-number{
                background: #FE1B1B;
                color: white;
            }
            .item-name,
            .mntl-dash_item-data span,
            .item-ftr,
            .item-total{
                color:white;
            }
            .mntl-dash_item-data span:not(.item-name),
            .item-ftr{opacity: 0.5;}
        }
    }
    &:hover{
        box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.4);
    }
    &.diabetic {
        .item-number {
            background: $diabeticColor;
            color: white;
        }
    }
    &.colema {
        .item-number {
            background: $colemaColor;
            color: white;
        }
    }
}

.title {
    color: $brand-primary;
    font-family: $fontTitle;
    font-size: 30px;
    font-weight: 600;
    padding-left: 30px;

    @include media-breakpoint-up(md){
        font-size: 34px;
        height: auto;
        width: auto;
        line-height: 34px;
    }
}

.name-patient {
    position: absolute;
    bottom: 10px;
    width: 90%;
    left: 5%;
    height: 90px;
    align-items: center;
    justify-content: space-between;
    display: flex;

    .name {
        font-size: 38px;
        font-family: $fontTitle;
    }

    figure {
        background-color: $brand-primary;
        border-radius: 50%;
        height: 250px;
        overflow: hidden;
        width: 250px;
        position: absolute;
        right: 0;
        bottom: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.pages-calendar {
    margin-top: -5px;
    a {
        color: white;
        font-size: 16px;
        padding: 0 20px;
        margin: 0 5px;
        height: 10px;

        &:hover, &.active {
            text-decoration: underline;
        }
    }
}

.bottom-element {
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 0;
}

.paginate-terapia {
    padding: 0 20px;
    &.active {
        text-decoration: underline;
    }
}
