body{
    font-family: $fontSite;
    font-size: 18px;

    &.patient-print-medical-assessment {
        main {
            aside {
                display: none !important;
            }
        }
    }
}

.img-fluid{
    max-width: 100%;
    height: auto;
}
.text-right{
    text-align: right;
}
.dFlex{
    display: flex;
    flex-wrap: wrap;
}
.noPad{
    padding: 0;
}
.noMargin{
    margin: 0;
}
.noPad-md{
    @include media-breakpoint-up(md){
        padding: 0;
    }
}
.float-left {
    float: left;
}
.mntl-login{
    width: 100%;
    @include media-breakpoint-up(md){
        display: flex;
        flex-wrap: wrap;
    }
    >div{
        height: 100vh;
    }
    &_img{
        background: url('/img/back/cover-login.webp') no-repeat center center /cover;
    }
    .form-title{
        color:$brand-primary;
        font-family: $fontTitle;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
        @include media-breakpoint-up(lg){
            font-size: 62px;
        }
    }
    &_form{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        label{
            color:$brand-secondary;
            font-size: 12px;
            display: block;
            margin-bottom: 5px;
        }
        input:not([type="checkbox"]){
            background: white;
            border: solid 1px #d9dce2;
            border-radius: 4px;
            padding: 0 20px;
            height: 55px;
            width: 100%;
            margin-bottom: 15px;
            &:focus{
                border-color: $brand-primary;
                outline: none;
            }
        }
    }
    &_remember{
        margin-bottom: 20px;
    }
    .form-check{
        position: relative;
        input{
            cursor: pointer;
            display: none;
        }
        label {
            cursor: pointer;
            color: #b0b3b7;
            font-size: 14px;
            position: relative;
            &:before {
                content:'';
                appearance: none;
                background-color: #f9fafd;
                border: 2px solid #d9dce2;
                border-radius: 4px;
                padding: 10px;
                display: inline-block;
                position: relative;
                vertical-align: middle;
                cursor: pointer;
                margin-right: 5px;
            }
        }
        input:checked + label:after {
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            left: 9px;
            width: 6px;
            height: 14px;
            border: solid $brand-primary;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
    }
    button{
        background: $brand-primary;
        border: none;
        border-radius: 4px;
        color:white;
        font-size: 14px;
        letter-spacing: 4.2px;
        text-transform: uppercase;
        width: 100%;
        height: 57px;
        padding: 17px 20px;
        text-align: center;
    }
}

body{
    &.dashboard{
        background: #f9fafd;
    }
}

main{
    @include media-breakpoint-up(lg){
        display: flex;
        flex-wrap: wrap;
        min-height: 100vh;
    }
}

.dash-btn{
    border-radius: 26px;
    font-size: 11px;
    letter-spacing: 3.3px;
    text-transform: uppercase;
    padding: 3px 14px;
    &_agendar{
        background: $brand-primary;
        color:white;
    }
    &_comida{
        background: #d2bda9;
        color:white;
    }
    &_pagado{
        background: #1f3666;
        color:white;
    }
    &_overdate{
        background: #FE1B1B;
        color: white !important;
        font-size: 10px !important;
        text-align: center;
        margin-top: 8px;
    }
    &_available {
        background: #1f3666;
        color: white !important;
        font-size: 10px !important;
        text-align: center;
        margin-top: 8px;
    }

    &.future-reservation {
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 115px;
    }
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.swal2-styled.swal2-confirm {
    width: 150px;
    height: 57px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 16px 20px;
    border-radius: 4px;
    letter-spacing: 5.7px !important;
    text-transform: uppercase !important;
    background-color: $brand-primary !important;
    border: 0;
    color: white !important;
}
.swal2-styled.swal2-cancel {
    width: 150px;
    height: 57px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 16px 20px;
    border-radius: 4px;
    letter-spacing: 5.7px !important;
    text-transform: uppercase !important;
    background-color: transparent !important;
    border: 0;
    color: $brand-primary !important;
}

.text-center {
    text-align: center;
}

.btn-green {
    padding: 10px 15px;
    border-radius: 4px;
    letter-spacing: 5.7px;
    text-transform: uppercase;
    background-color: #447167;
    border: 0;
    color: white;
    &:hover {
        background-color: white;
        color: #447167;
    }
}
.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.alert-success {
    color: white;
    background-color: $brand-primary;
    border-color: #c3e6cb;
}

.alert {
    border-radius: 10px;
    color: #fff;
    height: 60px;
    left: 0;
    margin: 0 auto;
    position: fixed;
    right: 0;
    width: 80%;
    z-index: 1;
    padding-top: 19px;
    padding-left: 34px;

    &.alert-success {
        background-color: green;
    }
    &.alert-warning {
        background-color: #ff8a00;
    }
    &.alert-error, &.alert-danger {
        background-color: #db3d3f;
    }
}

.backForRemarkHead {
    background-color: white;
    border-radius: 4px;
    text-align: center;
    font-family: Mukta;
    font-size: 23px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 6.4px;
    color: $brand-primary;
    padding-top: 10px;
    height: 55px;
    position: fixed;
    width: 100%;
    z-index: 3;
}

.backForRemark {
    background-color: rgb(0,0,0,0.6);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.wrap-hours {
    font-size: 36px;
    font-weight: 200;
}

.subtitle {
    color: $textcolor;
    font-size: 16px;
}

.subtitle-line {
    color: $brand-primary;
    font-weight: 600;
    font-size: 19px;
    margin-left: 8px;
}

.wrap-profile {
    .img-thumbnail {
        margin: 35px auto;
        border-radius: 50%;
        display: block;
        width: 250px;
        height: 250px;
        max-width: 300px;
        object-fit: cover;
        box-shadow: 1px 1px 15px black;
    }

    .wrap-main-box {
        margin: 0 auto;
        width: 80%;
    }
    .box {
        //border: 1px solid $brand-primary;
        //box-shadow: inset 0 0 3px #447167;
        padding: 20px;
    }
    span {
        color: $textcolor;
        display: inline-block;
        margin-right: 15px;
    }

    label {
        color: $brand-primary;
        font-size: 16px;
        display: inline-block;

        &.nickname {
            text-align: center;
            display: block;
            font-style: italic;
            font-size: 22px;
        }
    }

}

.buttons-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
}

.calendar-dashboard {
    position: relative;
    color: $brand-primary;
    text-transform: uppercase;
    letter-spacing: 5.7px;
}
.consulta-dashboard {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: $brand-primary;
    text-transform: uppercase;
    letter-spacing: 5.7px;
    width: 330px;

    &.active {
        text-decoration: underline;
    }
}
.crear-consulta-dashboard {
    position: absolute;
    top: 30px;
    left: 30px;
    color: $brand-primary;
    text-transform: uppercase;
    letter-spacing: 5.7px;

    &.active {
        text-decoration: underline;
    }
}

.self-center {
    display: block !important;
    margin: 0 auto !important;
}

.history-data_inf-item {
    .wrap-img {
        background-color: transparent;
        border: 0;
        height: auto;
        margin-top: 0;
        width: 185px;
    }
}

.wrap-img {
    &.rounded {
        img {
            border-radius: 50%;
        }
    }
}

.dFlex {
    display: flex !important;
}
.align-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.justify-space-btw {
    justify-content: space-between;
}
.justify-space-around {
    justify-content: space-around;
}
.direction-col {
    flex-direction: column;
}
.gap10 {
    gap: 10px;
}

.wrap-indicators {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .diabetesIndicator {
        position: relative;
        font-size: 19px;

        &::before {
            content: "•";
            color: $diabeticColor;
            font-size: 50px;
            left: -22px;
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
        }
    }
    .colemasIndicator {
        position: relative;
        font-size: 19px;

        &::before {
            content: "•";
            color: $colemaColor;
            font-size: 50px;
            left: -22px;
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
        }
    }

    .mealsIndicator {
        position: relative;
        font-size: 19px;

        &::before {
            content: "•";
            color: $brand-secondary;
            font-size: 50px;
            left: -22px;
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
        }
    }
}
.label-primary {
    color: $brand-primary;
}
.atentionText {
    color: $dangerColor;
    font-weight: 700;
}

.openTickets {
    background-color: red;
    border-radius: 50%;
    color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    top: 0;
    right: 22px;
    height: 20px;
    width: 20px;
}
