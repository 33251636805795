.bill-account {

    .history-container {
        position: relative;
        padding: 30px 0 0 0;
        max-width: 900px;

        &.tickets {
            max-width: 1000px;

        }

        .add-product-service {
            color: $brand-primary;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.79;
            letter-spacing: 0.14px;
            text-align: left;
            position: absolute;
            right: 0;
            top: 35px;

            img {
                margin-left: 7px;
            }
        }

        .history-data_inf {
            box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.06);

            .history-data_inf-item {
                position: relative;
                padding: 19px;

                &.disabled {
                    opacity: 0.6;
                }

                .history-data_name {
                    font-size: 19px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.8;
                    letter-spacing: 0.32px;
                    position: relative;

                    small {
                        color: $brand-primary;
                        font-weight: 700;
                        font-size: .775em;
                    }

                    .bullet-product {
                        margin-left: 10px;
                        width: 10px;
                        height: 10px;
                        background-color: red;
                        border-radius: 50%;
                        display: inline-block;
                        position: absolute;
                        left: 3px;
                        top: 9px;

                        &.paid {
                            background-color: $brand-primary;
                        }
                    }
                }

                .history-data_date {
                    color: $brand-primary;
                    text-align: right;
                    font-size: 20px;
                }

                .history-remove {
                    border: 1px solid $brand-primary;
                    border-radius: 50%;
                    padding: 5px;
                    position: absolute;
                    right: 15px;
                    top: 25px;
                    height: 23px;
                    width: 23px;

                    a {
                        background-image: url("/img/close.svg");
                        background-size: 100% auto;
                        display: block;
                        height: 100%;
                        width: 100%;
                        position: relative;
                        opacity: 1;
                    }
                }
                .item-discount {
                    border: 1px solid $brand-primary;
                    border-radius: 50%;
                    padding: 5px;
                    position: absolute;
                    right: 45px;
                    top: 25px;
                    height: 23px;
                    width: 23px;

                    &.small {
                        right: 45px;
                    }

                    a {
                        background-image: url('/img/discount.webp');
                        background-size: 100% auto;
                        display: block;
                        height: 100%;
                        width: 100%;
                        position: relative;
                        opacity: 1;
                    }

                    &.total-account {
                        right: 26px;
                        top: 28px;
                    }
                }

                &.total {
                    display: block;
                    text-align: right;
                    height: 85px;
                    padding: 30px 0;

                    span {
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.63;
                        letter-spacing: 0.32px;
                        display: inline-block;

                        &.total {
                            color: $brand-primary;
                            display: inline-block;
                            font-size: 32px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.81;
                            letter-spacing: 0.64px;
                            padding-right: 60px;
                            text-align: right;
                            padding-left: 35px;
                        }
                        &.extra-charge {
                            color: #ff5733;
                            display: inline-block;
                            font-size: 18px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.81;
                            letter-spacing: 0.64px;
                            padding-right: 60px;
                            text-align: right;
                            padding-left: 35px;
                        }
                    }
                }

                &.medical-report {
                    background-color: red;
                    span {
                        color: red;
                        small {
                            color: $brand-primary;
                            font-weight: 600;
                            font-size: 14px;
                        }
                    }
                }
            }
            //.history-data_inf-item

        }

    }
}

.f-d-column {
    flex-direction: column;
}

.medical-report {
    .history-data_inf {
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.06);

        .history-data_inf-item {
            position: relative;
            padding: 19px;

            &.disabled {
                opacity: 0.6;
            }

            .history-data_name {
                font-size: 19px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: 0.32px;
                position: relative;

                small {
                    color: $brand-primary;
                    font-weight: 700;
                    font-size: .775em;
                }

                .bullet-product {
                    margin-left: 10px;
                    width: 10px;
                    height: 10px;
                    background-color: red;
                    border-radius: 50%;
                    display: inline-block;
                    position: absolute;
                    left: 3px;
                    top: 9px;

                    &.paid {
                        background-color: $brand-primary;
                    }
                }
            }

            .history-data_date {
                color: $brand-primary;
                text-align: right;
                font-size: 20px;
            }

            .history-remove {
                border: 1px solid $brand-primary;
                border-radius: 50%;
                padding: 5px;
                position: absolute;
                right: 15px;
                top: 25px;
                height: 23px;
                width: 23px;

                a {
                    background-image: url("/img/close.svg");
                    background-size: 100% auto;
                    display: block;
                    height: 100%;
                    width: 100%;
                    position: relative;
                    opacity: 1;
                }
            }
            .item-discount {
                border: 1px solid $brand-primary;
                border-radius: 50%;
                padding: 5px;
                position: absolute;
                right: 45px;
                top: 25px;
                height: 23px;
                width: 23px;

                &.small {
                    right: 45px;
                }

                a {
                    background-image: url('/img/discount.webp');
                    background-size: 100% auto;
                    display: block;
                    height: 100%;
                    width: 100%;
                    position: relative;
                    opacity: 1;
                }

                &.total-account {
                    right: 26px;
                    top: 28px;
                }
            }

            &.total {
                display: block;
                text-align: right;
                height: 85px;
                padding: 30px 0;

                span {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.63;
                    letter-spacing: 0.32px;
                    display: inline-block;

                    &.total {
                        color: $brand-primary;
                        display: inline-block;
                        font-size: 32px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 0.81;
                        letter-spacing: 0.64px;
                        padding-right: 60px;
                        text-align: right;
                        padding-left: 35px;
                    }
                    &.extra-charge {
                        color: #ff5733;
                        display: inline-block;
                        font-size: 18px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 0.81;
                        letter-spacing: 0.64px;
                        padding-right: 60px;
                        text-align: right;
                        padding-left: 35px;
                    }
                }
            }

            &.medical-report {
                span {
                    small {
                        color: $brand-primary;
                        font-weight: 600;
                        font-size: 14px;
                    }
                }
            }
        }
        //.history-data_inf-item

    }

}

.underline {
    text-decoration: underline;
}

input {
    color: $brand-primary;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 15px 20px;
    border-radius: 4px;
    border: solid 1px #d9dce2;
    background-color: #fff;
    width: 100%;
}
