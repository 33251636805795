// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
// @import '~bootstrap/scss/bootstrap-grid';
// @import '~bootstrap/scss/bootstrap-reboot';
// @import '~bootstrap/scss/badge';

@import "sweetalert2/src/sweetalert2.scss";
@import "slick-carousel/slick/slick";
@import "slick-carousel/slick/slick-theme";

@import "~react-big-calendar/lib/css/react-big-calendar.css";

@import "dash";
@import "nav";
@import "recepcion";
@import "history";
@import "patient";
@import "account";
@import "calendar";
@import "therapies";
@import "kitchen";

.ReactModalPortal {
    z-index: 99;
    position: relative;
}

.youtube-modal {
    .close-modal {
        position: absolute;
        right: -5px;
        top: -5px;
        font-size: 25px;
    }
    .embed-responsive {
        margin-top: 12px;
        //     position: relative;
        //     display: block;
        //     width: 100%;
        //     padding: 0;
        //     overflow: hidden;
        //     &::before {
        //         padding-top: 56.25%;
        //         display: block;
        //         content: "";
        //     }
    }
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

#bigCalendar {
    width: 100%;
}

.globe-message {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: $brand-primary;
    padding: 10px 20px;
    border: 0;
    color: white !important;
    &:hover {
        opacity: 0.9;
    }
}

.wrap-message-bottom {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 400px;
    height: 300px;
    border: 2px solid $brand-primary;
    border-radius: 0 8px 20px 0 rgba(0, 0, 0, 0.06);
    background-color: white;
    border-radius: 4px;
    padding: 10px;

    h2 {
        color: $brand-primary;
        font-size: 20px;
        font-weight: bold;
    }
    button {
        background-color: $brand-primary;
        color: white;
        border: 0;
        padding: 8px 25px;
        float: right;
    }
}

.buttons-format {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    button {
        background-color: $brand-primary;
        color: white;
        border: 0;
        padding: 8px 25px;
    }
    div {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
    }
}
